$(function () {

    $('#year').replaceWith((new Date()).getFullYear());

    // Animate elements on your page as you scroll.
    AOS.init({
        disable: 'mobile',
    });

    initNavigation();
    initSliders();
    initValidation();
    displayContactFormMessage();
});

function displayContactFormMessage() {

    let cookies = {};

    document.cookie.split(';').forEach(function (cookie) {
        let pairs = cookie.split('=');
        cookies[pairs[0]] = pairs[1];
    });

    if (cookies.contact_form === 'ok') {
        $('.section.contact-message-ok').removeClass('d-none');
        document.cookie = 'contact_form=; Max-Age=-99999999;';
    }
}

function initNavigation() {

    // Active links as you scroll the page.
    // Since there aren't specific styles for active links
    // let's disable this functionality.

    // let $mainNav = $('#mainNav');

    // if ($mainNav.length > 0) {
    //     new bootstrap.ScrollSpy(document.body, {
    //         target: '#mainNav',
    //         offset: 74
    //     });
    // }

    let $navbarToggler = $('.navbar-toggler');
    let $navItems = $('#navbarResponsive .nav-link');

    // Close navbar menu when selecting an element.
    $navItems.on('click', function (e) {
        if (window.getComputedStyle($navbarToggler[0]).display !== 'none') {
            $navbarToggler.click();
        }
    });
}

function initValidation() {

    $('input[type="tel"]').on('input', function (e) {
        this.value = this.value
            .replace(/[^+\s\-0-9\(\)]/g, '');
    });

    let $forms = $('form.needs-validation');

    $forms.on('submit', function (e) {

        let form = this;
        let $form = $(this);

        $form.addClass('was-validated');

        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        }
    });

    $forms.find('.form-control').on('change blur', function (e) {
        $(this).removeClass('is-valid is-invalid');
        if (this.checkValidity()) {
            $(this).addClass('is-valid');
        } else {
            $(this).addClass('is-invalid');
        }
    });

    $forms.find('[type="submit"').on('click', function (e1) {

        $forms.find('.form-control, .form-check-input').each(function (e2) {

            $(this).removeClass('is-valid is-invalid');

            if (this.checkValidity()) {
                $(this).addClass('is-valid');
            } else {
                $(this).addClass('is-invalid');
            }
        });

        if ($forms.find('.form-control.is-invalid, .form-check-input.is-invalid').length > 0) {
            e2.preventDefault();
            e2.stopPropagation();
        }
    });
}

function initSliders() {

    let $tests = $('#tests');
    let $testsVal = $('#testsSliderVal');
    let $products = $('#products');
    let $productsVal = $('#productsSliderVal');
    let $price = $('#price');

    function updatePrice() {

        let tests = $tests.data('slider').getValue();
        let products = $products.data('slider').getValue();

        if (!isNaN(tests) && !isNaN(products)) {
            let price = 30 * tests * products;
            $price.text(price);
        }
    }

    $tests.slider({
        formatter: function(value) {
            return 'Número de tests: ' + value;
        }
    });

    $tests.on('slide', function(slideEvt) {
        $testsVal.text(slideEvt.value);
        updatePrice();
    });

    $products.slider({
        formatter: function(value) {
            return 'Productos: ' + value;
        }
    });

    $products.on('slide', function(slideEvt) {
        $productsVal.text(slideEvt.value);
        updatePrice();
    });

    updatePrice();
}
